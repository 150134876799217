import axios from 'axios';
import {toast} from 'react-toastify';
import {
  API_CALL,
  SET_START_REQUEST,
  SET_STOP_REQUEST,
} from '../Redux/Actions/actionTypes';
import {Auth} from 'aws-amplify';

export function apiAction({
  url = '',
  method = 'GET',
  data = null,
  onSuccess = null,
  onSuccessDispatch = null,
  onFailure = null,
  callback = null,
  label = '',
  headers = {},
  successMessage = null,
  errorMessage = null,
  responseType = 'application/json',
  loader = true,
  lambda = false,
  lambdaUrl = null,
  lambdaKey = null,
}) {
  return {
    type: API_CALL,
    payload: {
      url,
      method,
      data,
      onSuccess,
      onSuccessDispatch,
      onFailure,
      callback,
      label,
      headers,
      successMessage,
      errorMessage,
      responseType,
      loader,
      lambda,
      lambdaUrl,
      lambdaKey,
    },
  };
}

const apiMiddleware =
  ({dispatch, getState}) =>
  (next) =>
  async (action) => {
    try {
      next(action);

      if (action.type !== API_CALL) return;
      let token = null;
      let lambdaKey = null;

      if (action.payload.lambda) {
        if (action.payload.lambdaUrl && action.payload.lambdaUrl !== null) {
          action.payload.url = action.payload.lambdaUrl + action.payload.url;
        } else {
          action.payload.url =
            process.env.REACT_APP_LAMBDA_ENDPOINT + action.payload.url;
        }
        action.payload.data = {
          ...action.payload.data,
          dbEnv: process.env.REACT_APP_NODE_ENV,
        };
        const session = await Auth.currentSession();
        token = session.accessToken.jwtToken;
      } else {
        action.payload.url =
          process.env.REACT_APP_PROXY_TARGET + action.payload.url;
        token = localStorage['auth_token'];
      }
      if (action.payload.lambdaKey && action.payload.lambdaKey !== null) {
        lambdaKey = action.payload.lambdaKey;
      }

      const {
        url,
        method,
        data,
        onSuccess,
        onSuccessDispatch,
        onFailure,
        callback,
        headers,
        successMessage,
        errorMessage,
        responseType,
        loader,
      } = action.payload;

      if (localStorage.getItem(url + method) === 'true') {
        //console.log('prevented -> '+ url+method)
        //return
      } else {
        //set timer
        localStorage.setItem(url + method, 'true');

        setTimeout(function () {
          localStorage.removeItem(url + method);
        }, 1000 || process.env.DEBOUNCE_MS);
      }

      const dataOrParams = ['GET', 'DELETE'].includes(method)
        ? 'params'
        : 'data';
      // axios default configs
      axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || '';
      axios.defaults.headers.common['Content-Type'] = responseType;

      if (lambdaKey && lambdaKey !== null) {
        axios.defaults.headers.common['X-API-Key'] = btoa(lambdaKey);
        headers.Authorization = `Basic ${btoa(lambdaKey)}`;
      } else {
        axios.defaults.headers.common['X-API-Key'] =
          process.env.REACT_APP_JWT_SECRET;
        headers.Authorization = `Bearer ${token}`;
      }

      if (loader) {
        dispatch({type: SET_START_REQUEST, isRequest: true});
      }

      headers['selected-schema'] = 'warehouse_fantasia'; // Aggiunta dell'header

      axios
        .request({
          url,
          method,
          headers,
          [dataOrParams]: data,
        })
        .then((response) => {
          if (response.data.success || response.status === 200) {
            if (typeof onSuccess === 'function') {
              onSuccess(response || {});
            }
            if (typeof onSuccessDispatch === 'function') {
              dispatch(onSuccessDispatch(response || {}));
            }
            if (successMessage !== null) {
              toast.success(successMessage);
            }
            if (typeof callback === 'function') {
              callback(response.data || {});
            }

            if (!response.data.noPendingRequest) {
              if (response.data.delayStopRequest) {
                setTimeout(() => {
                  dispatch({type: SET_STOP_REQUEST, isRequest: false});
                }, response.data.delayStopRequest);
              } else {
                dispatch({type: SET_STOP_REQUEST, isRequest: false});
              }
            }
          } else {
            switch (response.data.level) {
              case 'info':
                toast.info(response.data.message);
                break;
              case 'warning':
                toast.warning(response.data.message);
                break;
              case 'error':
                toast.error(response.data.message);
                break;
              default:
                toast.info(response.data.message);
                break;
            }

            if (typeof onFailure === 'function') {
              onFailure();
            }
            dispatch({type: SET_STOP_REQUEST, isRequest: false});
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.noPendingRequest) {
            dispatch({type: SET_STOP_REQUEST, isRequest: false});
          }
          //if (error?.response?.data?.refreshToken) {
          //dispatch(refreshToken(error.response.data.refreshToken))
          //}
          if (error) {
            dispatch({type: SET_STOP_REQUEST, isRequest: false});
            console.log('\nError -> ' + error);

            if (
              error.response.status === 401 ||
              error.response.status === 429
            ) {
              toast.error('Effettua il login');
            } else {
              if (errorMessage !== null) {
                if (error.response.data?.level === 1) {
                  if (error.response.data.message) {
                    console.log(error);
                    toast.error(error.response.data.message);
                  } else {
                    toast.error(errorMessage);
                  }
                } else {
                  toast.warn(errorMessage);
                }
              } else {
                toast.error('Errore. Contattare gli amministratori');
              }
              if (typeof onFailure === 'function') {
                onFailure();
              }
              if (error?.response?.data?.exception) {
                toast.error(error.response.data.exception);
              }
            }
          }
        });
    } catch {
      dispatch({type: SET_STOP_REQUEST, isRequest: false});
    }
  };

export default apiMiddleware;
